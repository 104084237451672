<template>
  <div class="row g-6">
    <div class="col-xl-4">
      <div class="card" style="width: 700px">
        <div class="card-body d-flex flex-column">
          <!--begin::Heading-->
          <div class="text-center">
            <!--begin::Title-->
            <div class="card-label fw-bolder fs-2">
              {{ $t("modals.interview.startInterview.title") }}
            </div>
            <span class="text-muted fs-7 mb-2">{{
              $t("modals.interview.startInterview.desc")
            }}</span>
            <!--end::Title-->
          </div>
          <!--end::Heading-->

          <!--begin::Form-->
          <Form
            id="kt_account_profile_details_form"
            class="form"
            :validation-schema="inviteStaffForm"
            @submit="submit"
          >
            <!--begin::Card body-->
            <div class="card-body p-9 ps-6 pb-1">
              <div class="row mb-7">
                <!-- İlan Başlığı -->
                <div class="col-md-6 mb-5">
                  <div class="card border border-gray-300 shadow-sm rounded">
                    <div class="card-body text-center">
                      <div class="fw-bold text-gray-600">Başvurulan İş İlanı</div>
                      <h4 class="mt-2 text-dark">
                        Hybrid Çalışacak Frontend Developer
                      </h4>
                    </div>
                  </div>
                </div>

                <!-- Mülakat Türü -->
                <div class="col-md-6 mb-5">
                  <div class="card border border-gray-300 shadow-sm rounded">
                    <div class="card-body text-center">
                      <div class="fw-bold text-gray-600">Mülakat Türü</div>
                      <h4 class="mt-2 text-primary">Teknik Mülakat</h4>
                    </div>
                  </div>
                </div>

                <!-- Soru Sayısı -->
                <div class="col-md-6 mb-5">
                  <div class="card border border-gray-300 shadow-sm rounded">
                    <div class="card-body text-center">
                      <div class="fw-bold text-gray-600">Soru Sayısı</div>
                      <h4 class="mt-2 text-dark">8</h4>
                    </div>
                  </div>
                </div>

                <!-- Süre -->
                <div class="col-md-6 mb-5">
                  <div class="card border border-gray-300 shadow-sm rounded">
                    <div class="card-body text-center">
                      <div class="fw-bold text-gray-600">Tahmini Süre</div>
                      <h4 class="mt-2 text-dark">3 dakika</h4>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Önemli Bilgiler ve Onaylar -->
              <div class="row">
                <div class="col-md-12">
                  <div class="fw-bold mb-3">
                    <h6>Önemli Bilgiler ve Onaylar</h6>
                    <ul>
                      <li>
                        Mülakat esnasında pencereyi değiştirmemeniz
                        gerekmektedir. Aksi takdirde bu durum skorunuza etki
                        edebilir.
                      </li>
                      <li>
                        Mülakat sesli ve görüntülü olarak yapılacaktır. Kamera
                        ve mikrofonunuzun çalıştığından emin olun.
                      </li>
                      <li>
                        Mülakat sırasında sorular açık uçlu, çoktan seçmeli veya
                        tek seçenekli olabilir. Tüm soruların doğru bir şekilde
                        yanıtlanması beklenmektedir.
                      </li>
                      <li>
                        Mülakat esnasında ses ve görüntü kaydı alınacaktır. Tüm
                        veriler kayıt altına alınacaktır.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!--begin::KVKK Onay-->
              <div class="row mt-4 mb-7">
                <div class="col-md-12">
                  <div class="fv-row">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="kvkkConsent"
                        v-model="kvkkConsent"
                      />
                      <label class="form-check-label" for="kvkkConsent">
                        Kişisel verilerimin işlenmesi için açık rızamı
                        veriyorum. Bu onay, mülakat süreci boyunca verilerimin
                        toplanması ve kullanılmasına izin verildiğini kabul
                        ettiğim anlamına gelir.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <!--end::KVKK Onay-->

              <!--begin::Submit Button-->
              <div class="d-flex justify-content-center">
                <button
                  type="submit"
                  id="kt_account_profile_details_submit"
                  ref="submitButton"
                  class="btn btn-primary"
                  :disabled="!kvkkConsent"
                >
                  <span class="indicator-label">{{
                    $t("common.button.start")
                  }}</span>
                  <span class="indicator-progress">
                    {{ $t("common.pleaseWait") }}
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span>
                  </span>
                </button>
              </div>
              <!--end::Submit Button-->
            </div>
            <!--end::Card body-->
          </Form>
          <!--end::Form-->
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "start-interview",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();

    // Create form validation object
    const inviteStaffForm = Yup.object().shape({
      name: Yup.string().required(t("validators_error.required")).label("Name"),
      surname: Yup.string()
        .required(t("validators_error.required"))
        .label("Surname"),
      email: Yup.string()
        .email(t("validators_error.email"))
        .required(t("validators_error.required"))
        .label("Email"),
    });

    const kvkkConsent = ref(false); // KVKK onay durumu

    const submit = (values: any) => {
      console.log(values);
      router.push({ path: `/pollsterUser` });
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.startInterview.title"));
    });

    return {
      inviteStaffForm,
      submit,
      kvkkConsent, // KVKK onay durumu
    };
  },
});
</script>
